<template>
    <SectionHero />

    <SectionSharedSpace />

    <SectionPrimaryFeatures />

    <SectionPlans />

    <SectionAboutUs />

    <SectionContactUs />
</template>
